import {createStore} from "vuex"
import axios from "axios"
import {formatAddress} from "@/utils/format";
import {createPatientFile, createPatientBioindicators} from "@/utils/axios_requests";


const store = createStore({
    state: {
        baseURL: process.env.VUE_APP_BACKEND_URL,
        token: "",
        user: {},
        orgId: "",
        patients: [],
        business: {},
        businessMembers: [],
        loadingGlobal: true,
        richCEP: {}
    },
    getters: {
        axiosInstance: (state) => {
            return axios.create({
                    baseURL: state.baseURL
                }
            )
        },
        axiosConfig: (state) => (config, contentType = 'application/json') => {
            return {
                headers: {
                    'Content-Type': contentType,
                    'Authorization': 'Bearer ' + state.token
                },
                ...(config && "params" in config ? {params: config.params} : null),
                ...(config && "data" in config ? {data: config.data} : null)
            }
        },
        getUser: (state) => {
            return state.user
        },
        getDoctorInfo: (state) => (doctorId) => {
            for (let i = 0; i < state.businessMembers.length; i++) {
                let member = state.businessMembers[i]
                if (member.user_id === doctorId) {
                    let crm = ''
                    if(member.user_metadata) {
                        if(member.user_metadata['crm']) {
                            crm = member.user_metadata['crm']
                        }
                    }
                    return {"name": member.name || "", "crm": crm,
                        "email": member.email || ""}
                }
            }
            return {
                'name': '',
                'crm': '',
                'email': '',
            }
        },
        getUserRoles: (state) => {
            for (let i = 0; i < state.businessMembers.length; i++) {
                if (state.businessMembers[i].user_id === state.user.sub) {
                    return state.businessMembers[i].roles
                }
            }
        },
        getBusiness: (state) => {
            return state.business
        },
        getBusinessAddress: (state) => {
            const clinic = state.business
            return formatAddress(clinic['address'], clinic['address_number'], clinic['address_obj'],
                clinic['district'], clinic['city'])
        },
        getBusinessMembers: (state) => {
            return state.businessMembers
        },
        getPatientById: (state) => (patientId) => {
            for (let i = 0; i < state.patients.length; i++) {
                if (state.patients[i].id === parseInt(patientId)) {
                    return state.patients[i]
                }
            }
            return {}
        },
        getPatients: (state) => {
            return state.patients
        },
        getRichCEP: (state) => {
            return state.richCEP
        },
        getLoadingGlobal: (state) => {
            return state.loadingGlobal
        }
    },
    mutations: {
        initState(state, payload) {
            if ("token" in payload) {
                state.token = payload.token
            }
            if ("orgId" in payload) {
                state.orgId = payload.orgId
            }
            if ("user" in payload) {
                state.user = payload.user
            }
        },
        setLoadingGlobal(state, value) {
            state.loadingGlobal = value
        },
        setUser(state, user) {
            state.user = user
        },
        setBusiness(state, business) {
            state.business = business
        },
        setBusinessMembers(state, members) {
            state.businessMembers = members
        },
        setPatients(state, patients) {
            state.patients = patients
        },
        setRichCEP(state, richCEP) {
            state.richCEP = richCEP
        },
        addPatient(state, data) {
            state.patients.push(data)
        },

        removeFromPatients(state, data) {
            const index = state.patients.findIndex(patient => patient.id === data.id)
            if (index >= 0) {
                state.patients.splice(index, 1)
            }
        },
        updatePatient(state, data) {
            const index = state.patients.findIndex(patient => patient.id === data.id)
            if (index >= 0) {
                state.patients.splice(index, 1, data)
            }
        },
        updateBusinessMember(state, data) {
            const index = state.businessMembers.findIndex(member => member.user_id === data.user_id)
            if (index >= 0) {
                let updatedBusinessMember = {...state.businessMembers[index]}
                updatedBusinessMember['roles'] = data.new_permissions
                state.businessMembers.splice(index, 1, updatedBusinessMember)
            }
        },
        removeBusinessMember(state, data) {
            const index = state.businessMembers.findIndex(member => member.user_id === data.user_ids[0])
            if (index >= 0) {
                state.businessMembers.splice(index, 1)
            }
        },
    },
    actions: {
        initState({commit}, payload) {
            commit("initState", payload)
        },
        setLoadingGlobal({commit}, value) {
            commit("setLoadingGlobal", value)
        },
        setUserRoles({state, commit}, roles) {
            const user = {...state.user}
            user.roles = roles
            commit("setUser", user)
        },
        setUserSignature({state, commit}, link){
            const user = JSON.parse(JSON.stringify(state.user))
            user.user_metadata.signature_url = link
            commit("setUser", user)
        },
        setBusinessMembers({commit, getters, dispatch}, members) {
            commit("setBusinessMembers", members)
            const roles = getters.getUserRoles
            dispatch("setUserRoles", roles)
        },
        initializeBusiness({dispatch, commit, getters, state}) {
            if (Object.values(getters.getBusiness).length) {
                return
            }
            return new Promise((resolve) => {
                return getters.axiosInstance.get("auth/organization",
                    getters.axiosConfig({
                        "params": {"user_id": getters.getUser.sub, 'org_id': state.orgId}
                    })).then(resp => {
                    commit("setBusiness", resp.data)
                    dispatch("setBusinessMembers", resp.data.members)
                    resolve()
                })
            })
        },
        setPatients({commit}, patients) {
            commit("setPatients", patients)
        },
        inviteMembers({getters}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.post('auth/organization_invite', data, getters.axiosConfig())
                    .then(() => {
                        resolve()
                    })
            })
        },

        createBusiness({commit, getters, dispatch}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.post('auth/organization', data, getters.axiosConfig())
                    .then((response) => {
                        let data = response.data
                        commit('initState', {"orgId": data.id})

                        let businessData = {
                            "id": data.id,
                            "cnpj_or_cpf": data.cnpj_or_cpf,
                            "display_name": data.display_name,
                            "country": data.country,
                            "address": data.address,
                            "address_number": data.address_number,
                            "address_obs": data.address_obs,
                            "district": data.district,
                            "city": data.city,
                            "state": data.state,
                            "cep": data.cep,
                            "phone": data.phone
                        }

                        let businessMembers = data.members

                        commit('setBusiness', businessData)
                        dispatch('setBusinessMembers', businessMembers)
                        resolve()
                    })
            })
        },
        updateBusiness({commit, getters}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.put('auth/organization', data, getters.axiosConfig())
                    .then(() => {
                        commit('setBusiness', data)
                        resolve()
                    })
            })
        },
        updateMemberPermission({commit, getters}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.put('auth/organization_permissions', data, getters.axiosConfig())
                    .then(() => {
                        commit('updateBusinessMember', data)
                        resolve()
                    })
            })
        },
        removeMemberPermission({commit, getters}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.delete('auth/organization_members', getters.axiosConfig({
                    "data": data
                }))
                    .then(() => {
                        commit('removeBusinessMember', data)
                        resolve()
                    })
            })
        },
        initializePatients({commit, getters, state}) {
            if (state.patients.length) {
                return
            }
            return new Promise((resolve) => {
                return getters.axiosInstance.get('patients/list',
                    getters.axiosConfig({
                        "params": {"org_id": state.orgId}
                    })).then((
                    response) => {
                    const data = response.data['items']
                    commit("setPatients", data)
                    resolve()
                })
            })
        },
        createPatient({commit, getters}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.post('patients/new', data)
                    .then((response) => {
                        createPatientFile(store.getters.axiosInstance, store.getters.axiosConfig, data.files, response.data.id)
                        createPatientBioindicators(store.getters.axiosInstance, store.getters.axiosConfig, response.data.id)
                        commit('addPatient', response.data)
                        resolve()
                    })
            })
        },
        removePatient({commit, getters}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.delete('patients/delete/' + data['id'], getters.axiosConfig())
                    .then(() => {
                        commit('removeFromPatients', data)
                        resolve()
                    })
            })
        },
        updatePatient({commit, getters}, data) {
            return new Promise((resolve) => {
                return getters.axiosInstance.put('patients/update', data, getters.axiosConfig())
                    .then((response) => {
                        createPatientFile(store.getters.axiosInstance, store.getters.axiosConfig, data.files, response.data.id)
                        commit('updatePatient', response.data)
                        resolve()
                    })
            })
        },
        getRichCEP({commit}, data) {
            return new Promise((resolve) => {
                return axios.get('https://viacep.com.br/ws/' + data['cep'] + '/json/')
                    .then((response) => {
                        commit("setRichCEP", response.data)
                        resolve()
                    })
            })
        }
    }
})

export default store
