import {createAuth0} from "@auth0/auth0-vue";
import {getQueryVariable} from "@/utils/query";

export const orgIdURL = getQueryVariable("organization")
const invitation = getQueryVariable("invitation")
export const orgIdLocalStorage = localStorage.getItem("org_id")

const auth0Settings = {
    domain: process.env.VUE_APP_AUTH0_DOMAIN,
    clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
    authorizationParams: {
        redirect_uri: window.location.origin,
        audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        useRefreshTokens: true,
        cacheLocation: 'localstorage',
        ...(orgIdLocalStorage ? {organization: orgIdLocalStorage} : null),
        ...(invitation ? {invitation: invitation} : null)
    },
}

export const auth = createAuth0(auth0Settings)