<template>
  <v-app>
    <v-main>
      <loader-component v-show='globalLoading' color="#9F365B" :global-content="true"></loader-component>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup>
import LoaderComponent from "@/components/LoaderComponent";
import {computed} from "vue";
import store from "@/store/state"

const globalLoading = computed(() => store.state.loadingGlobal)

</script>

<style>

::-webkit-scrollbar {
  width: 0 !important;
}

.v-main {
  /* background: radial-gradient(#d69f8a,#647db5); */
  background-color: rgb(256,244,236);
  overflow-scrolling: auto;
}

.v-expansion-panel-title__overlay {
  opacity: 0 !important;
}

.confirm-dialog{
  width: 70dvw;
}

.v-text-field .v-field-label--floating {
  text-overflow: ellipsis !important;
  overflow: hidden !important;
  white-space: nowrap !important;
  font-size: 10px;
  padding-right: 20px;
  display: block;
}


</style>
