export function convertToLocalDate(serverDateStr) {
    let serverDate = new Date(serverDateStr)
    let localDate = serverDate.toLocaleString('pt-BR', { hour12: true })

    return localDate.replace(", ", ' às ')
}

export function formatAddress(address, address_number, address_obj, district, city) {
    let clinicAddress = address + ", " + address_number
    if (address_obj) {
        clinicAddress += ", " + address_obj
    }
    clinicAddress += ", " + district + " - " + city
    return clinicAddress
}

export function formatCEP(rawCEP) {
    let formattedCEP = ''
    if (rawCEP) {
        formattedCEP = rawCEP.substring(0, 2) + "." + rawCEP.substring(2, 5) + "-" + rawCEP.substring(5, 8)
    }

    return formattedCEP;
}

export function formatCNPJ(rawCNPJ) {
    let formattedCNPJ = ''
    if (rawCNPJ) {
        formattedCNPJ = rawCNPJ.substring(0, 2) + "." + rawCNPJ.substring(2, 5) + "." + rawCNPJ.substring(5, 8) + "/" + rawCNPJ.substring(8, 12) + "-" + rawCNPJ.substring(12, 14)
    }

    return formattedCNPJ;
}

export function formatCPF(rawCPF) {
    return rawCPF.substring(0, 3) + "." + rawCPF.substring(3, 6) + "." + rawCPF.substring(6, 9) + "-" + rawCPF.substring(9, 12)
}

export function formatDate(strDate) {
    const dateTimeObj = new Date(strDate)
    return dateTimeObj.getDate() + "/" + (dateTimeObj.getMonth() + 1) + "/" + dateTimeObj.getFullYear()
}

export function formatDatePython(strDate) {
    if (strDate) {
        const splitDate = strDate.split("-")
        return splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0]
    }
    return ''
}

export function formatPhone(rawPhone) {
    let formattedPhone;
    let ddd;
    let phone;
    ddd = "(" + rawPhone.substring(0, 2) + ")";
    phone = rawPhone.substring(2, 7) + '-' + rawPhone.substring(7)
    formattedPhone = ddd + ' ' + phone;

    return formattedPhone;
}

export function formatTimestampPython(strDate) {
    if (strDate) {
        const date = strDate.split("T")[0]
        const splitDate = date.split("-")
        const timestamp = strDate.split("T")[1]
        const splitTime = timestamp.split(".")
        return splitDate[2] + '/' + splitDate[1] + '/' + splitDate[0] + " às " + splitTime[0]
    }
    return ''
}

export function getInitials(name) {
    let splitName = name.split(" ")
    let initials
    if (splitName.length >= 2) {
        // avoid getting whitespace as initial
        if (splitName[1][0]) {
            // build initials from the first two names - separated by whitespace
            initials = splitName[0][0] + splitName[1][0];
        } else {
            // get the two initials from the first name
            initials = splitName[0][0] + splitName[0][1]
        }
    } else {
        initials = splitName[0][0] + splitName[0][1]
    }
    initials = initials ? initials : ''
    return initials.toUpperCase();

}

export function unformatCEP(formattedCEP) {
    return formattedCEP.replace(".", '').replace("-", '')
}

export function unformatDate(formattedDate) {
    const datePython = unformatDatePython(formattedDate)
    return new Date(datePython)
}

export function unformatDatePython(formattedDate) {
    if (formattedDate.search('/') !== -1) {
        const splitDate = formattedDate.split("/")
        return splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
    } else {
        return formattedDate
    }
}

export function unformatDateBR(formattedDate) {
    if (formattedDate.search('/') !== -1) {
        const splitDate = formattedDate.split("/")
        return splitDate[0] + '-' + splitDate[1] + '-' + splitDate[2]
    } else {
        const splitDate = formattedDate.split("-")
        return splitDate[2] + '-' + splitDate[1] + '-' + splitDate[0]
    }
}

export function unformatCNPJ(formattedCNPJ) {
    return formattedCNPJ.replaceAll(".", '').replaceAll("-", '').replaceAll("/", '')
}

export function unformatCPF(formattedCPF) {
    if (formattedCPF) {
        return formattedCPF.replaceAll(".", '').replaceAll("-", '')
    } else {
        return ""
    }
}

export function unformatPhone(prettyPhone) {
    if (prettyPhone) {
        let ddd = prettyPhone.substring(0, 4)
        // remove ()
        ddd = ddd.substring(1, 3)
        let phone = prettyPhone.substring(5)
        // replace - for an ''
        phone = phone.replace("-", "")

        return ddd + phone
    } else {
        return ""
    }
}

export function getFormattedOption(options, optionValue, returnType) {
    for (let i = 0; i < options.length; i++) {
        let currentOption = { ...options[i] }
        if (currentOption['value'] === optionValue) {
            if (returnType === 'object') {
                return currentOption;
            } else if (returnType === 'title') {
                return currentOption.title;
            } else if (returnType === 'value') {
                return currentOption.value;
            }
        }
    }
}

export function getObjectById(objectsArray, objectId) {
    for (let i = 0; i < objectsArray.length; i++) {
        let currentObject = { ...objectsArray[i] };
        if (currentObject['id'] === objectId) {
            return currentObject;
        }
    }
}

export function convertArrayToCommaStr(arr) {
    let str = ""
    for (let i = 0; i < arr.length; i++) {
        str += arr[i] + ","
    }
    str = str.slice(0, -1)
    return str
}

export function compareDates(date1, date2) {
    const [month1, day1, year1] = date1.split('/');
    const [month2, day2, year2] = date2.split('/');

    if (year1 > year2) {
        return true;
    } else if (year1 < year2) {
        return false;
    } else {
        if (month1 > month2) {
            return true;
        } else if (month1 < month2) {
            return false;
        } else {
            if (day1 > day2) {
                return true;
            } else return day1 >= day2;
        }
    }
}

export function getHistoric(field, historicalData) {
    if (field in historicalData) {
        return {
            'data': historicalData[field]['data'],
            'options': historicalData[field]['options'],
            'chartType': historicalData[field]['chartType']
        }
    } else {
        return {}
    }
}

export function validateCPF(cpf) {
    let arrayCPF = cpf.split('').map(Number)
    if (new Set(arrayCPF).size === 1) {
        return false;
    }
    let digitArrayCPF = arrayCPF.slice(0, 9)
    let testRange = [11, 10, 9, 8, 7, 6, 5, 4, 3, 2]
    let sumResult = digitArrayCPF.reduce(function(r,a,i){return r+a*testRange.slice(1)[i]},0)
    if(sumResult % 11 < 2){
        if(arrayCPF[9] !== 0){
            return false
        }
    }else{
        if(arrayCPF[9] !== (11 - sumResult % 11)){
            return false
        }
    }
    digitArrayCPF = arrayCPF.slice(0, 10)
    sumResult = digitArrayCPF.reduce(function(r,a,i){return r+a*testRange[i]},0)
    if(sumResult % 11 < 2){
        if(arrayCPF[10] !== 0){
            return false
        }
    }else{
        if(arrayCPF[10] !== (11 - sumResult % 11)){
            return false
        }
    }

    return true
}


export function validateCNPJ(cnpj) {
    let arrayCNPJ = cnpj.split('').map(Number)
    if (new Set(arrayCNPJ).size === 1) {
        return false;
    }
    let digitArrayCNPJ = arrayCNPJ.slice(0, 12)
    let testRange = [6, 5, 4, 3, 2, 9, 8, 7, 6, 5, 4, 3, 2]
    let sumResult = digitArrayCNPJ.reduce(function(r, a, i){return r + a * testRange.slice(1)[i]}, 0)
    if(sumResult % 11 < 2){
        if(arrayCNPJ[12] !== 0){
            return false
        }
    }else{
        if(arrayCNPJ[12] !== (11 - sumResult % 11)){
            return false
        }
    }
    digitArrayCNPJ = arrayCNPJ.slice(0, 13)
    sumResult = digitArrayCNPJ.reduce(function(r, a, i){return r + a * testRange[i]}, 0)
    if(sumResult % 11 < 2){
        if(arrayCNPJ[13] !== 0){
            return false
        }
    }else{
        if(arrayCNPJ[13] !== (11 - sumResult % 11)){
            return false
        }
    }

    return true
}