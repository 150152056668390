export function createPatientFile(axiosInstance, axiosConfig, files, patientId) {
    const newFiles = files.filter((obj) => obj instanceof File)
    if (!newFiles.length) {
        return
    }
    const formData = new FormData()
    for (let i = 0; i < newFiles.length; i++) {
        let currentFile = newFiles[i]
        formData.append('files', newFiles[i])
        formData.append('descriptions', currentFile.description || '')
    }
    return axiosInstance.post('patients/file', formData,
        axiosConfig({
            'params': {
                'patient_id': patientId
            }
        }, 'multipart/form-data'))
}

export function createPatientBioindicators(axiosInstance, axiosConfig, patientId) {
    let payload = {}
    payload.id = ''
    payload.patient_id = patientId
    payload.bioindicator_1_name = 'Hb (g/dL)'
    payload.bioindicator_2_name = 'Hematócrito (g/dL)'
    payload.bioindicator_3_name = 'Glicemia (mg/dL)'
    payload.bioindicator_4_name = 'Testosterona Total (ng/dL)'
    payload.bioindicator_5_name = '25 OH Vitamina D (ng/mL)'
    axiosInstance.post('patients/bioindicators/new', payload,
        axiosConfig()).then(() => {
        })
}