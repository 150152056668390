import {createRouter, createWebHistory} from "vue-router"
import {authGuard} from '@auth0/auth0-vue'
import store from "@/store/state"
import {auth, orgIdLocalStorage, orgIdURL} from "@/auth0"


const routes = [
    {
        path: '/',
        name: 'home',
        component: () => import('../views/MainView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/patients',
        name: 'patients',
        component: () => import('../views/PatientsView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/patients/new',
        name: 'patients_new',
        component: () => import('../views/UpdatePatientView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/patients/edit/:patient_id?',
        name: 'patients_edit',
        component: () => import('../views/UpdatePatientView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/new_patient',
        name: 'new_patient',
        component: () => import('../views/ExternalPatientForm.vue'),
        beforeEnter: [globalBeforeEnter]
    },
    {
        path: '/user_info',
        name: 'user_info',
        component: () => import('../views/UserView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/user_info/edit/',
        name: 'user_info_edit',
        component: () => import('../views/UpdateUserView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/first_access_business',
        name: 'first_access_business',
        component: () => import('../views/FirstAccessBusinessView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/business',
        name: 'business',
        component: () => import('../views/BusinessView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/appointment_options/:patient_id?',
        name: 'appointment_options',
        component: () => import('../views/AppointmentOptionsView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/appointment/:patient_id?',
        name: 'appointment',
        component: () => import('../views/AppointmentView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/appointment/:patient_id?/new/',
        name: 'appointments_new',
        component: () => import('../views/UpdateAppointmentView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
        meta: {roles: ["Doctor"]}
    },
    {
        path: '/appointment/:patient_id?/edit/:appointment_id?',
        name: 'appointments_edit',
        component: () => import('../views/UpdateAppointmentView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/drug',
        name: 'drug',
        component: () => import('../views/DrugView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/prescription_template',
        name: 'prescription_template',
        component: () => import('../views/PrescriptionTemplateView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/prescription_template/new',
        name: 'prescription_template_new',
        component: () => import('../views/UpdatePrescriptionTemplateView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/prescription/edit/:prescription_id?',
        name: 'prescription_template_edit',
        component: () => import('../views/UpdatePrescriptionTemplateView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/prescription_options',
        name: 'prescription_options',
        component: () => import('../views/PrescriptionOptionsView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/prescription/:patient_id?',
        name: 'prescription',
        component: () => import('../views/PrescriptionView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/prescription/:patient_id?/new/',
        name: 'prescriptions_new',
        component: () => import('../views/UpdatePrescriptionView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
        meta: {roles: ["Doctor"]}
    },
    {
        path: '/prescription/:patient_id?/edit/:prescription_id?',
        name: 'prescriptions_edit',
        component: () => import('../views/UpdatePrescriptionView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/prescription/:patient_id?/new/special/',
        name: 'special_prescriptions_new',
        component: () => import('../views/UpdateSpecialPrescriptionView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
        meta: {roles: ["Doctor"]}
    },
    {
        path: '/prescription/:patient_id?/edit/:prescription_id?/special/',
        name: 'special_prescriptions_edit',
        component: () => import('../views/UpdateSpecialPrescriptionView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/logout',
        name: 'logout',
        component: () => import('../views/LogOut.vue'),
        beforeEnter: authGuard
    },
    {
        path: '/exam_options',
        name: 'exam_options',
        component: () => import('../views/ExamOptionsView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/exam_template',
        name: 'exam_template',
        component: () => import('../views/ExamTemplateView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/exam_template/new',
        name: 'exam_template_new',
        component: () => import('../views/UpdateExamTemplateView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/exam_template/edit/:exam_id?',
        name: 'exam_template_edit',
        component: () => import('../views/UpdateExamTemplateView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/exam',
        name: 'exam',
        component: () => import('../views/ExamView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/exam/:patient_id?',
        name: 'exam',
        component: () => import('../views/ExamView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter],
    },
    {
        path: '/exam/:patient_id?/new',
        name: 'exam_new',
        component: () => import('../views/UpdateExamView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/exam/:patient_id?/edit/:exam_id?',
        name: 'exam_edit',
        component: () => import('../views/UpdateExamView.vue'),
        beforeEnter: [authGuard, globalBeforeEnter]
    },
    {
        path: '/permission_insufficient',
        name: 'permissions_insufficient',
        component: () => import('../views/PermissionInsufficient'),
        beforeEnter: authGuard
    },
    {
        path: '/:catchAll(.*)',
        component: () => import('../views/ErrorView'),
        name: 'NotFound',
        beforeEnter: authGuard
    }

]

const router = createRouter({
        history: createWebHistory(),
        routes
    }
)


async function globalBeforeEnter(to) {
    // This guards sets orgId, token, user, user_roles and localStorage

    await store.dispatch("setLoadingGlobal", true)
    // Continue if route is authentication login
    if (to.fullPath.includes("/?code")) {
        return true
    }

    if (to.path === '/first_access_business') {
        return true
    }

    if (orgIdURL) {
        await store.dispatch("initState", {"orgId": orgIdURL})
        return true
    }

    if (store.state.orgId && Object.keys(store.state.user).length && ('roles' in store.state.user) &&
        store.state.user.roles && store.state.user.roles.length && orgIdLocalStorage) {
        return true
    }

    const {getAccessTokenSilently, user} = auth

    if (!store.state.orgId) {
        if (orgIdLocalStorage) {
            const token = await getAccessTokenSilently({organization: orgIdLocalStorage})
            await store.dispatch('initState', {'orgId': orgIdLocalStorage, 'token': token})

        } else {
            // Try get orgId from backend
            let token = await getAccessTokenSilently()
            let orgId = ""
            await store.dispatch('initState', {'token': token})
            await store.getters.axiosInstance.get("auth/org_id_by_user",
                store.getters.axiosConfig({
                    "params": {"user_id": user.value.sub}
                })).then((resp) => {
                orgId = resp.data
            })
            if (!orgId) {
                // If orgId is not store yet, Redirect to create organization
                return {"path": "first_access_business"}
            } else {
                // Reload screen. We must have org id in localStorage. See afterEach hook
                await store.dispatch('initState', {'orgId': orgId})
                localStorage.setItem('login', 'true')
            }
        }

    }

    if (!store.state.user) {
        await store.dispatch('initState', {'user': user})
    }

    if (!('roles' in store.state.user) || !store.state.user.roles) {
        await store.getters.axiosInstance.get("auth/user/roles",
            store.getters.axiosConfig({
                "params": {"user_id": user.value.sub, "org_id": store.state.orgId}
            })).then(resp => {
            let user_obj = {...user.value}
            user_obj.roles = resp.data
            store.dispatch("initState", {"user": user_obj})
        })

    }

    if (!orgIdLocalStorage) {
        // Reload screen. We must have org id in localStorage. See afterEach hook
        localStorage.setItem('login', 'true')
        await localStorage.setItem('org_id', store.state.orgId)
    }

    return true
}


router.beforeResolve(async to => {
    if (to.meta.roles) {
        const route_roles = to.meta.roles
        const user_roles = store.state.user['roles']
        for (let i = 0; i < route_roles.length; i++) {
            if (user_roles && user_roles.includes(route_roles[i])) {
                return true
            }
        }
        return {"path": "permission_insufficient"}
    }
    return true
})

router.afterEach(async () => {
    if (localStorage.getItem('login')) {
        localStorage.removeItem('login')
        location.reload()
    } else {
        await store.dispatch("setLoadingGlobal", false)
    }
})

export default router