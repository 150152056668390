import {formatAddress} from "@/utils/format";
import store from "@/store/state";


export function getQueryVariable(variable) {
    const query = window.location.search.substring(1);
    const vars = query.split('&');
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split('=');
        if (decodeURIComponent(pair[0]) === variable) {
            return decodeURIComponent(pair[1]);
        }
    }
    return ''
}

export async function getPatient(patientId) {
    const patient = store.getters.getPatientById(patientId)
    if ('id' in patient) {
        return patient
    } else {
        return store.getters.axiosInstance.get("patients/" + patientId,
            store.getters.axiosConfig({
            "params": {
              "get_archived": true,
            }
          })).then((resp) => {
            if(resp.data['deleted_at'] === null) {
                store.commit('addPatient', resp.data)
            }
            return resp.data


        })
    }
}

export async function getPatientInfo(patientId) {
    const patient = await getPatient(patientId)
    return {
        'name': patient.name,
        'address': formatAddress(patient['address'], patient['address_number'], patient['address_obj'],
            patient['district'], patient['city']),
        'birth_date': new Date(patient.birth_date)
    }
}
