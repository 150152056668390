import {createApp} from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import {loadFonts} from './plugins/webfontloader'
import store from "./store/state"
import router from "./router"
import {auth} from "@/auth0"
import * as Sentry from "@sentry/vue";


const app = createApp(App)
Sentry.init({
    app,
    dsn: window.location.origin.includes("nutroapp") || window.location.origin.includes('myoc') ? process.env.VUE_APP_SENTRY_DSN : "",
    integrations: [
        new Sentry.BrowserTracing({
            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [process.env.VUE_APP_SENTRY_TARGET],
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        }),
    ],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
});

loadFonts().then(() => {
    app.use(store)
        .use(vuetify)
        .use(router)
        .use(auth)
        .mount("#app")
})

